<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="出入库单号" prop="invoiceNo">
          <el-input v-model="params.invoiceNo" />
        </el-form-item>
        <el-form-item label="货单类型" prop="invoiceType">
          <el-select v-model="params.invoiceType" clearable>
            <el-option value="1" label="入库"></el-option>
            <el-option value="2" label="出库"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出入库类型" prop="invoiceMethod">
          <el-select v-model="params.invoiceMethod" clearable>
            <el-option value="001" label="采购入库"></el-option>
            <el-option value="002" label="礼品入库"></el-option>
            <el-option value="003" label="其他入库"></el-option>
            <el-option value="100" label="退货出库"></el-option>
            <el-option value="101" label="其他出库"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出入库时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品名称" prop="goodsName">
          <el-input v-model="params.goodsName" />
        </el-form-item>
        <el-form-item label="商品条码" prop="barNo">
          <el-input v-model="params.barNo" />
        </el-form-item>
        <el-button type="primary" :loading="loading" @click="outExel">
          导出明细
        </el-button>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="invoiceNo"
          label="出\入库单号"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="invoiceOrder"
          label="货单编号"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="invoiceTypeDesc"
          label="货单类型"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="invoiceMethodDesc"
          label="出\入库类型"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          label="仓库"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="barNo"
          label="条码"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsCount"
          label="数量"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="price"
          label="单价（元）"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="total"
          label="出入库小计（元）"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="操作时间"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="remark"
          label="备注"
        ></el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],

  data() {
    return {
      loading: false,
      groupList: [],
      tableData: [],
      params: {
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },

  created() {
    this.getData()
  },
  methods: {
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.date && params.date.length) {
          params.createTimeEnd = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.createTimeStart = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.createTimeEnd = undefined
          params.createTimeStart = undefined
        }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        const res = await this.$api.commodity.getGoodsList(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(item => {
            return item
          })
        }
        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async outExel() {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.date && params.date.length) {
          params.createTimeEnd = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.createTimeStart = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.createTimeEnd = undefined
          params.createTimeStart = undefined
        }
        await this.$api.commodity.exportGoodExel(params)
        this.loading = false
      } catch (error) {
      } finally {
        this.loading = false
      }
      // exportGoodExel
    }
  }
}
</script>

<style scoped lang="scss"></style>
